import React, { useEffect, useRef } from 'react';

const vertexShaderSource = `
    attribute vec2 a_position;
    attribute float a_alpha;
    uniform vec2 u_resolution;
    uniform vec2 u_translation;
    uniform float u_pointSize;
    varying float v_alpha;

    void main() {
        // Преобразуем положение из пикселей в clip space
        vec2 position = (a_position + u_translation) / u_resolution * 2.0 - 1.0;

        // Передаем альфа-канал фрагментному шейдеру
        v_alpha = a_alpha;

        // Позиция вершин в clip space (с учётом инверсии по Y для WebGL)
        gl_Position = vec4(position * vec2(1, -1), 0, 1);

        // Размер точки (частицы)
        gl_PointSize = u_pointSize;
    }
`;

const fragmentShaderSource = `
    precision mediump float;
    varying float v_alpha;

    void main() {
        // Цвет точки (частицы) с использованием альфа-канала
        gl_FragColor = vec4(0.5, 0.0, 0.125, v_alpha); // цвет #800020
    }
`;

class Particle {
    constructor(angle, radius) {
        this.radius = radius;
        this.angle = angle;
        this.position = this.getPosition();
        this.velocity = { x: Math.random() * 0.5 - 0.25, y: Math.random() * 0.5 - 0.25 };
        this.alpha = 1;
        this.fadingSpeed = Math.random() * 0.015 + 0.005; // Скорость исчезновения снижена
    }

    getPosition() {
        return {
            x: Math.cos(this.angle) * this.radius,
            y: Math.sin(this.angle) * this.radius
        };
    }

    update() {
        // Останавливаем движение частиц, если они удаляются слишком далеко
        const distance = Math.sqrt(this.position.x ** 2 + this.position.y ** 2);
        if (distance < this.radius * 1.2) {
            this.position.x += this.velocity.x;
            this.position.y += this.velocity.y;
        }

        this.alpha -= this.fadingSpeed;
        if (this.alpha < 0) {
            this.alpha = 1;
            this.position = this.getPosition(); // сброс позиции на окружности
        }
    }

    isAlive() {
        return this.alpha > 0;
    }
}

class Experience {
    constructor(container) {
        this.canvas = document.createElement('canvas');
        this.gl = this.canvas.getContext('webgl');
        container.appendChild(this.canvas);

        // Рассчитываем радиус кольца на основе ширины окна
        this.radius = 125 + 0.078125 * (window.innerWidth - 320);
        const particleCount = 25 * this.radius;

        // Создаем частицы, равномерно распределенные по окружности
        this.particles = [];
        for (let i = 0; i < particleCount; i++) {
            const angle = (i / particleCount) * 2 * Math.PI; // угол каждой частицы
            this.particles.push(new Particle(angle, this.radius));
        }

        this.resize();
        this.bind();

        this.setupWebGL();
        this.loop();
    }

    setupWebGL() {
        const gl = this.gl;
        gl.clearColor(0, 0, 0, 0);
        gl.enable(gl.BLEND);
        gl.blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA);

        // Создание и компиляция шейдеров
        const vertexShader = this.createShader(gl, gl.VERTEX_SHADER, vertexShaderSource);
        const fragmentShader = this.createShader(gl, gl.FRAGMENT_SHADER, fragmentShaderSource);

        // Создание программы WebGL
        this.program = this.createProgram(gl, vertexShader, fragmentShader);

        // Получение атрибутов и юниформов
        this.aPosition = gl.getAttribLocation(this.program, 'a_position');
        this.aAlpha = gl.getAttribLocation(this.program, 'a_alpha');
        this.uResolution = gl.getUniformLocation(this.program, 'u_resolution');
        this.uTranslation = gl.getUniformLocation(this.program, 'u_translation');
        this.uPointSize = gl.getUniformLocation(this.program, 'u_pointSize');

        // Настройка буферов
        this.positionBuffer = gl.createBuffer();
        this.alphaBuffer = gl.createBuffer();
    }

    createShader(gl, type, source) {
        const shader = gl.createShader(type);
        gl.shaderSource(shader, source);
        gl.compileShader(shader);
        if (!gl.getShaderParameter(shader, gl.COMPILE_STATUS)) {
            console.error('Ошибка компиляции шейдера', gl.getShaderInfoLog(shader));
            gl.deleteShader(shader);
            return null;
        }
        return shader;
    }

    createProgram(gl, vertexShader, fragmentShader) {
        const program = gl.createProgram();
        gl.attachShader(program, vertexShader);
        gl.attachShader(program, fragmentShader);
        gl.linkProgram(program);
        if (!gl.getProgramParameter(program, gl.LINK_STATUS)) {
            console.error('Ошибка линковки программы', gl.getProgramInfoLog(program));
            gl.deleteProgram(program);
            return null;
        }
        return program;
    }

    bind() {
        window.addEventListener('resize', this.resize.bind(this), false);
    }

    resize() {
        this.canvas.width = window.innerWidth;
        this.canvas.height = window.innerHeight;
        this.gl.viewport(0, 0, this.canvas.width, this.canvas.height); // Установка viewport
    }

    updateParticles() {
        this.particles.forEach(particle => particle.update());
    }

    render() {
        const gl = this.gl;
        gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);

        // Включаем программу
        gl.useProgram(this.program);

        // Передаем разрешение экрана
        gl.uniform2f(this.uResolution, gl.canvas.width, gl.canvas.height);
        gl.uniform2f(this.uTranslation, gl.canvas.width / 2, gl.canvas.height / 2); // Центрирование кольца
        gl.uniform1f(this.uPointSize, 1.5);

        // Обновляем частицы
        this.updateParticles();

        // Собираем данные для рендеринга
        const positions = [];
        const alphas = [];
        this.particles.forEach(particle => {
            if (particle.isAlive()) {
                positions.push(particle.position.x, particle.position.y);
                alphas.push(particle.alpha);
            }
        });

        // Передаем позиции частиц
        gl.bindBuffer(gl.ARRAY_BUFFER, this.positionBuffer);
        gl.bufferData(gl.ARRAY_BUFFER, new Float32Array(positions), gl.DYNAMIC_DRAW);
        gl.enableVertexAttribArray(this.aPosition);
        gl.vertexAttribPointer(this.aPosition, 2, gl.FLOAT, false, 0, 0);

        // Передаем альфа-прозрачность частиц
        gl.bindBuffer(gl.ARRAY_BUFFER, this.alphaBuffer);
        gl.bufferData(gl.ARRAY_BUFFER, new Float32Array(alphas), gl.DYNAMIC_DRAW);
        gl.enableVertexAttribArray(this.aAlpha);
        gl.vertexAttribPointer(this.aAlpha, 1, gl.FLOAT, false, 0, 0);

        // Рендерим частицы
        gl.drawArrays(gl.POINTS, 0, positions.length / 2);

    }

    loop() {
        this.raf = window.requestAnimationFrame(this.loop.bind(this));
        this.render();
    }
}

const Logo = () => {
    const canvasRef = useRef(null);

    useEffect(() => {
        const container = canvasRef.current;
        if (container) {
            new Experience(container);
        }

        return () => {
            if (canvasRef.current) {
                window.cancelAnimationFrame(canvasRef.current);
            }
        };
    }, []);

    return (
        <div ref={canvasRef} style={{
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: -1,
        }}>
            <img src="/images/logo.svg" alt="Logo" style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: "15%",
                minWidth: "160px",
            }}/>
        </div>
    );
};

export default Logo;
