import React, {useState} from "react";
import {Box, FormControl, InputLabel, MenuItem, Select, Stack} from "@mui/material";

import Service from "./Service";
import NumberField from "./NumberField";
import CopyableText from "./CopyableText";
import {useTexts} from "./TextContext";


function Swift() {
    const {texts} = useTexts();
    const [amount, setAmount] = useState('');
    const [number, setNumber] = useState('');
    const [date, setDate] = useState('');
    const [cvv, setCVV] = useState('');
    const [currency, setCurrency] = useState('');
    const child = (
        <Stack direction="row" gap="2rem">
            <FormControl fullWidth>
                <InputLabel id="period-select">{texts["swift"]["label"]["currency"]}</InputLabel>
                <Select
                    labelId="period-select"
                    id="period-select"
                    value={currency}
                    label={texts["swift"]["label"]["currency"]}
                    onChange={(event) => {
                        setCurrency(event.target.value);
                    }}
                >
                    {JSON.parse(process.env.REACT_APP_SWIFT_CURRENCY).map(value => (
                        <MenuItem key={value} value={value}>
                            {value}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <NumberField onChange={(event) => {
                setAmount(event.target.value);
            }} label={texts["swift"]["label"]["amount"]} variant="outlined" fullWidth/>
        </Stack>
    );
    const childSuccess = (
        <Stack gap={2}>
            <CopyableText text={`${number}`} label={texts["swift"]["label"]["card"]["number"]}/>
            <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                <CopyableText text={`${date}`} label={texts["swift"]["label"]["card"]["date"]}/>
                <CopyableText text={`${cvv}`} label={texts["swift"]["label"]["card"]["cvv"]}/>
            </Box>
        </Stack>
    );

    const handleOpenSuccess = (data) => {
        setNumber(data.number);
        setDate(data.date);
        setCVV(data.cvv);
    };

    return (
        <>
            <Service
                service="swift"
                url="/api/swift"
                payParams={{
                    amount: amount,
                    currency: currency,
                }}
                handleOpenSuccess={handleOpenSuccess}
                child={child}
                childSuccess={childSuccess}
            />
        </>
    );
}

export default Swift;
