import React, {useState} from "react";
import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Typography,
    Link,
    Stack,
} from "@mui/material";

import Service from "./Service";
import {useTexts} from "./TextContext";


function VPN() {
    const {texts} = useTexts();
    const [period, setPeriod] = useState('');
    const [downloadLink, setDownloadLink] = useState(null);
    const child = (
        <FormControl fullWidth>
            <InputLabel id="period-select">{texts["vpn"]["label"]["period"]}</InputLabel>
            <Select
                labelId="period-select"
                id="period-select"
                value={period}
                label={texts["vpn"]["label"]["period"]}
                onChange={(event) => {
                    setPeriod(event.target.value);
                }}
            >
                {JSON.parse(process.env.REACT_APP_VPN_PERIODS).map(value => (
                    <MenuItem key={value} value={value}>
                        {value}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
    const childSuccess = downloadLink && (
        <Stack gap={2}>
            <Typography variant="body1">
                {texts.vpn["download-link"]["before"]}
                <Link href={downloadLink} download="config.conf">{texts.vpn["download-link"]["after"]}</Link>
            </Typography>
            <Link href="https://cront.app/wg-guide">{texts.vpn["download-link"]["guide"]}</Link>
        </Stack>
    );

    const handleOpenSuccess = (data) => {
        const blob = new Blob([data], {type: 'application/octet-stream'});
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'config.conf';
        a.click();
        setDownloadLink(url);
    };


    return (
        <>
            <Service
                service="vpn"
                url="/api/vpn"
                payParams={{period: period}}
                handleOpenSuccess={handleOpenSuccess}
                child={child}
                childSuccess={childSuccess}
            />
        </>
    );
}

export default VPN;
